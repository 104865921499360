
export default {
    state: {},
    getters: {
        getEnvParam: () => (param) => process.env[param],
        getAppEnvParam: (state, getters) =>
            (param) => {
                const variable = param.split('-').join('_')
                return getters.getEnvParam(`VUE_APP_${variable}`)
            },
        app_spid: (state, getters) => +getters.getAppEnvParam('SPID') || 1,
    },
    actions: {},
    mutations: {},
}