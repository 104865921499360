/**
 * The file enables `@/store/index.js` to import all vuex modules in a one-shot manner.
 * There should not be any reason to edit this file.
 */

const file_paths = require.context('.', false, /\.js$/)
const modules = {}

file_paths.keys().forEach(path => {

    const module_name = path.replace(/(\.\/|\.js)/g, '')

    if (path != './index.js') {

        modules[module_name] = file_paths(path).default

    }
})

export default modules
