import Vue from 'vue'
import VueRouter from 'vue-router'
import permissions from './permissions'

Vue.use(VueRouter)

const routes = [
    {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import('@/views/sign-in'),
        beforeEnter: permissions(['!is-auth'], { name: 'dashboard' }),
    },
    {
        path: '/forgot-pass',
        name: 'forgot-pass',
        component: () => import('@/views/forgot-pass'),
        beforeEnter: permissions(['!is-auth'], { name: 'dashboard' }),
    },
    {
        path: '/new-pass/:code',
        name: 'new-pass',
        component: () => import('@/views/new-pass'),
        beforeEnter: permissions(['!is-auth'], { name: 'dashboard' }),
    },
    {
        path: '/', component: () => import('@/layouts/'), children: [
            { path: '', beforeEnter: (to, from, next) => next({ name: 'dashboard', replace: true }) },
            { path: 'dashboard', component: () => import('@/views/page-dashboard'),
                children: [
                    { path: '', name: 'dashboard', component: () => import('@/views/dashboard')},
                    { path: 'audit', name: 'audit', component: () => import('@/views/audit')},
                ]
            },
            { path: 'site-lists', name: 'site-lists', redirect: {name: 'dashboard'}},
            { path: 'sites-map', name: 'sites-map', component: () => import('@/views/dashboard')},
            { path: 'qms-templates', name: 'qms-templates', redirect: {name: 'dashboard'}},
            { path: 'users', name: 'users', redirect: {name: 'dashboard'}},
            { path: 'settings', name: 'settings', redirect: {name: 'dashboard'}},
        ], beforeEnter: permissions(['is-auth'], { name: 'sign-in' }),
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,

    // Scroll up when changing route
    scrollBehavior(to, from, savedPosition) {
        let scroll = { x: 0, y: 0 }

        if (to.hash) {
            scroll = { selector: to.hash }
        } else if (savedPosition) {
            scroll = savedPosition
        }

        return scroll
    },
})

export default router
