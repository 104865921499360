import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueGoogleCharts from 'vue-google-charts'
 
Vue.use(VueGoogleCharts)

import styles from './assets/scss/main.scss'

import axiosMiddleware from '@/middlewares/axios-middleware'
import axios from 'axios'

Vue.use(axiosMiddleware, axios, store, router)
Vue.prototype.$axios = axios

Vue.config.productionTip = false

store.dispatch('AutoLogin').then(() => {
    new Vue({
        router,
        store,
        styles,
        render: (h) => h(App),
    }).$mount('#app')
}).catch(() => {
    console.log('AutoLogin promise error')
})
