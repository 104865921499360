import dynStorage from '@/utils/storage-dynamic';
import axios from 'axios';

const state = {
    auth_data: null,
};

const getters = {
    jwt:            state => state.auth_data && state.auth_data.JWT || "",
    jwt_expiry:     state => state.auth_data && state.auth_data.JWTExpiry && state.auth_data.JWTExpiry || 0,

    jwt_is_valid:   (state, getters) => getters.jwt_expiry > Math.round(new Date().getTime() / 1000),
    jwt_is_expired: (state, getters) => !getters.jwt_is_valid,

    // Current identity information
    id_uuid:         state => state.auth_data && state.auth_data.UUID || "",
    id_spid:         state => state.auth_data && state.auth_data.SPID || "",
    id_first_name:   state => state.auth_data && state.auth_data.FirstName || "",
    id_last_name:    state => state.auth_data && state.auth_data.LastName || "",
    id_email:        state => state.auth_data && (state.auth_data.Email || state.auth_data.AuthID) || "",
    id_phone:        state => state.auth_data && state.auth_data.MobileNumber || "",
    id_provider:     state => state.auth_data && state.auth_data.IdentityProvider || "",
    id_auth_id:      state => state.auth_data && state.auth_data.AuthID || "",

    id_display_name: (state, getters) => [getters.id_first_name, getters.id_last_name].filter(x => x).join(" ") || getters.id_email || "",

};

const mutations = {
    // mut_auth_data stores the raw Authentication response
    mut_auth_data(state, newAuthData) {
        if (newAuthData &&
            newAuthData.JWT &&
            newAuthData.JWTExpiry &&
            (newAuthData.JWTExpiry > Math.round(new Date().getTime() / 1000))
        ) {
            state.auth_data = newAuthData
            state.jwt = newAuthData.JWT
            state.jwt_expiry = newAuthData.JWTExpiry
            dynStorage.set('jwt', state.jwt)
            dynStorage.set('jwt_expiry', state.jwt_expiry)
        } else {
            state.auth_data = null
            state.jwt = null
            state.jwt_expiry = 0
            dynStorage.remove('jwt')
            dynStorage.remove('jwt_expiry')
        }
    },

    // mut_update_jwt is desgined to be called from the Axios handler. When the
    // axios middleware recieves the X-Auth-Token + X-Auth-Token-Expiry headers
    // in the HTTP(S) response, then the backend has auto-renewed the token,
    // and it should call this to update the locally cached JWT and JWTExpiry
    mut_update_jwt(state, { JWT, JWTExpiry }) {
        if (JWT &&
            JWTExpiry &&
            (JWTExpiry > Math.round(new Date().getTime() / 1000))
        ) {
            state.jwt = JWT
            state.jwt_expiry = JWTExpiry
            dynStorage.set('jwt', state.jwt)
            dynStorage.set('jwt_expiry', state.jwt_expiry)
        } else {
            state.auth_data = null
            state.jwt = null
            state.jwt_expiry = 0
            dynStorage.remove('jwt')
            dynStorage.remove('jwt_expiry')
        }
    },

    // mut_logout is designed to set the auth state back to the original
    // (logged out) state
    mut_logout(state) {
        dynStorage.remove('jwt')
        dynStorage.remove('jwt_expiry')

        state.auth_data = null
    },
};

const actions = {
    // LoginEmailPassword is a wrapped for AuthCheck
    // It is intended to be used for LOCAL Email and Password authentication
    LoginEmailPassword({ dispatch }, { login, pass }) {

        return dispatch('AuthCheck', {
            IdentityProvider: "LOCAL",
            AuthID: login,
            AuthCode: pass,
        })
    },

    RegisterUser({ dispatch }, { email, passnew, firstname, lastname, phone}) {

        return dispatch('Auth', {
            IdentityProvider: "LOCAL",
            AuthID: email,
            AuthCode: passnew,
            Email: email,
            MobileNumber: phone,
            FirstName: firstname,
            LastName: lastname,
        })
    },

    ChangePass( { dispatch }, {pass, passnew}) {

        return dispatch('UpdateLocalUser', {
            Password: passnew,
        })
    },

    // Auth makes the API call to add a new local user
    Auth({ dispatch, getters, commit }, payload) {

        payload.SPID = getters.app_spid
        payload.IncludeRoles = true

        return axios.post(`/v2/:spid/auth`, payload, { refresh: false }).then(response => {
            if (!response.data) {
                return Promise.reject(Error('no payload'))
            }

            commit('mut_auth_data', response.data)

            return Promise.resolve()
        }).catch(error => {
            if (error) {
                console.log('Auth ERROR:', error)
                return Promise.reject(error)
            }
            return Promise.reject(Error('unknown error in Auth'))
        })
    },

    // AuthCheck makes the API call to check authentication
    AuthCheck({ dispatch, getters, commit }, payload) {

        payload.SPID = getters.app_spid
        payload.IncludeRoles = true

        return axios.post(`/v2/:spid/auth_check`, payload, { refresh: false }).then(response => {
            if (!response.data) {
                return Promise.reject(Error('no payload'))
            }

            commit('mut_auth_data', response.data)

            return Promise.resolve()
        }).catch(error => {
                if (error) {
                    console.log('AuthCheck ERROR:', error)
                    return Promise.reject(error)
                }
                return Promise.reject(Error('unknown error in AuthCheck'))
        })
    },

    // AutoLogin is designed to be run when the application loads
    // to resume/recover the application authentication state
    // It will re-authenticate with the API to ensure the JWT is still valid
    AutoLogin({ dispatch, commit }) {
        const storedJWT = dynStorage.get('jwt')
        if (!storedJWT) {
            commit('mut_logout')
            // AutoLogin must always return a resolved Promise
            return Promise.resolve()
        }

        return dispatch('AuthCheck', {
            IdentityProvider: "JWT_REFRESH",
            AuthID: "jwt",
            AuthCode: storedJWT,
        }).then(() => {
            // AutoLogin must always return a resolved Promise
            return Promise.resolve()
        })
            .catch(() => {
                // AutoLogin must always return a resolved Promise
                return Promise.resolve()
            })
    },

    // Logout logs the user out, and removes all authentication state
    Logout({ dispatch, commit }) {
        commit('mut_logout')
    },

    // setJWT is called from axios middleware to update the local cache
    // of the JWT and JWT Expirty
    setJWT({ getters, commit }, { JWT, JWTExpiry }) {
        if (!JWT || JWT == "-" || JWTExpiry == -1) {
            commit('mut_logout')
            return
        }
        if (!JWTExpiry) {
            commit('mut_update_jwt', { JWT, JWTExpiry: getters.jwt_expiry })
            return
        }
        commit('mut_update_jwt', { JWT, JWTExpiry })
    },


    // UpdateLocalUser makes the API call to update user's data
    UpdateLocalUser({ getters, commit }, payload) {

        payload.SPID = getters.app_spid

        return axios.put(`/v2/:spid/auth/LOCAL/${getters.id_auth_id}`, payload, { refresh: false }).then(response => {
            if (!response.data) {
                return Promise.reject(Error('no payload'))
            }

            commit('mut_auth_data', response.data)

            return Promise.resolve()
        }).catch(error => {
            if (error) {
                console.log('UpdateLocalUser ERROR:', error)
                return Promise.reject(error)
            }
            return Promise.reject(Error('unknown error in UpdateLocalUser'))
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};